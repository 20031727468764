/* Sides */

.sides {
  @include vendor("display", "flex");
  @include vendor("flex-wrap", "wrap");
  border-top: 0 !important;

  & + * {
    border-top: 0 !important;
  }

  .con {
    display: flex;
    width: 100%;
  }

  article {
    // @include vendor("align-items", "flex-start");
    @include vendor("display", "flex");
    @include vendor(
      "transition",
      (
        "transform 0.25s ease",
        "opacity 0.25s ease",
        "filter 1s ease",
        "-webkit-filter 1s ease"
      )
    );
    @include padding(4em, 4em);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: default;
    min-height: 23em;
    overflow: hidden;
    position: relative;

    width: inherit;

    .image {
      display: none;
    }

    a {
      @include vendor("align-self", "end");
    }

    header {
      position: relative;
      z-index: 3;
      display: flex;
      flex-flow: wrap;
    }

    h3 {
      font-size: 1.75em;

      a {
        &:hover {
          color: inherit !important;
        }
      }
    }

    .link.primary {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 4;
    }

    &:before {
      @include vendor("transition", "opacity 0.5s ease");
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.75;
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    &:after {
      background-color: transparentize(_palette(bg), 0.75);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    &:hover {
      &:before {
        opacity: 0;
      }
    }

    &.is-transitioning {
      @include vendor("transform", "scale(0.95)");
      @include vendor("filter", "blur(0.5em)");
      opacity: 0;
    }

    &:nth-child(1) {
      &:before {
        background-color: _palette(side1);
      }
    }

    &:nth-child(2) {
      &:before {
        background-color: _palette(side2);
      }
    }
  }

  @include breakpoint(large) {
    article {
      @include padding(4em, 3em);
      height: 100%;
      min-height: 5em;
    }
  }

  @include breakpoint(medium) {
    article {
      @include padding(4em, 3em);
      height: 100%;
      min-height: 5em;
    }
  }

  @include breakpoint(small) {
    article {
      @include padding(3em, 1.5em);
      height: 100%;
      min-height: 5em;

      h3 {
        font-size: 1.5em;
      }
    }
  }

  // @include breakpoint(xsmall) {
  //   // display: block;

  //   article {
  //     height: 100%;
  //     // height: 20em;
  //     // width: 100% !important;
  //   }
  // }
}
