.card {
  display: block;
  padding: 1rem;
  width: 80%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
}
.card-full {
  background-color: #f5f7f9;
  border-bottom: 2px solid #d8dee9;
}

.border-primary {
  border: 4px solid _palette(highlight);
}

@media only screen and (min-width: 45em) {
  .row {
    display: flex;
    flex: 2 1 0px;
    align-items: center;
    padding: 1em;
  }
}
.col {
  flex-basis: 100%;
  //   padding: 10em;
}
.col40 {
  flex-basis: 40%;
  padding-top: 1em;
}
.col60 {
  flex-basis: 60%;
  //   padding: 10em;
}

.logo-img {
  flex-basis: 100%;
  width: 125px;
}
