/* Box */

.box {
  border: solid 1px _palette(border);
  margin-bottom: _size(element-margin);
  padding: 1.5em;

  > :last-child,
  > :last-child > :last-child,
  > :last-child > :last-child > :last-child {
    margin-bottom: 0;
  }

  &.alt {
    border: 0;
    border-radius: 0;
    padding: 0;
  }
}
