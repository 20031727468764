// Misc.
$misc: (
  z-index-base: 10000,
);

// Duration.
$duration: (
  menu: 0.35s,
  transition: 0.2s,
  banner: 2.5s,
);

// Size.
$size: (
  border-radius: 4px,
  element-height: 2.75em,
  element-margin: 2em,
  inner: 65em,
);

// Font.
$font: (
  family: (
    "Source Sans Pro",
    Helvetica,
    sans-serif,
  ),
  family-fixed: (
    "Courier New",
    monospace,
  ),
  weight: 300,
  weight-bold: 600,
  letter-spacing: 0.025em,
  letter-spacing-alt: 0.25em,
);

// Palette.
$palette: (
  bg: #def3fd,
  bg-alt: #c3ecff,
  fg: #000000,
  fg-bold: #000000,
  fg-light: rgba(0, 0, 0, 1),
  border: rgba(212, 212, 255, 0.1),
  border-bg: #fffdf2,
  highlight: #ddc700,
  highlight-light: #fdf7d0,
  accent1: #def3fd,
  accent2: #fdf7d0,
  accent3: #fdf7d0,
  accent4: #def3fd,
  accent5: #def3fd,
  accent6: #fdf7d0,
  side1: #def3fd,
  side2: #fffdf2,
);
