/* Contact Method */

.contact-method {
  margin: 0 0 _size(element-margin) 0;
  padding-left: 3.25em;
  position: relative;

  .icon {
    left: 0;
    position: absolute;
    top: 0;
  }

  h3 {
    margin: 0 0 (_size(element-margin) * 0.25) 0;
  }
}
