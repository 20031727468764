/* Section/Article */

section,
article {
  &.special {
    text-align: center;
  }
}

header {
  &.major {
    width: -moz-max-content;
    width: -webkit-max-content;
    width: -ms-max-content;
    width: max-content;
    max-width: 100%;
    margin-bottom: _size(element-margin);

    > :first-child {
      margin-bottom: 0;
      width: calc(100% + 0.5em);

      &:after {
        content: "";
        background-color: _palette(fg-bold);
        display: block;
        height: 2px;
        margin: 0.325em 0 0.5em 0;
        width: 100%;
      }
    }

    > p {
      font-size: 1em;
      font-weight: _font(weight-bold);
      // letter-spacing: _font(letter-spacing-alt);
      margin-bottom: 5;
      //   text-transform: uppercase;
    }

    body.is-ie & {
      > :first-child {
        &:after {
          max-width: 9em;
        }
      }

      > h1 {
        &:after {
          max-width: 100% !important;
        }
      }
    }
  }

  @include breakpoint(small) {
    &.major {
      > p {
        br {
          display: none;
        }
      }
    }
  }
}
